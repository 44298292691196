<script setup
        lang="ts">
import { useGdprStore } from '@reshepe/stores';
import ButtonAction     from '../button/action/button-action.vue';
import LinkInternal     from '../link/internal/link-internal.vue';
import LinkExternal     from '../link/external/link-external.vue';

withDefaults(defineProps<{
    astro?: boolean;
}>(), {
    astro: false,
});

const gdprStore = useGdprStore();

let policy_url   = '/cookies/policy';
let settings_url = '/cookies/settings';

const is_not_landing = !!import.meta.env.VITE_LANDING_URL;

if (is_not_landing) {
    policy_url   = import.meta.env.VITE_LANDING_URL + policy_url;
    settings_url = import.meta.env.VITE_LANDING_URL + settings_url;
}
</script>

<template>
    <div v-if="!gdprStore.closed"
         class="gdpr-notice--wrapper">
        <p class="gdpr-notice--message">
            we use cookies to improve your experience and for analytics. Learn more in our
            <LinkExternal v-if="is_not_landing"
                          text="cookies policy"
                          :url="policy_url" />
            <LinkInternal v-else
                          text="cookies policy"
                          :url="policy_url"
                          :astro="astro" />
        </p>
        <div class="gdpr-notice--buttons">
            <ButtonAction text="reject"
                          icon="i-clarity:times-line"
                          class="gdpr-notice--buttons__reject"
                          :shrink="false"
                          @click="gdprStore.reject()" />
            <ButtonAction text="accept"
                          icon="i-ic:round-check"
                          highlight
                          class="gdpr-notice--buttons__accept"
                          :shrink="false"
                          @click="gdprStore.accept()" />
        </div>
    </div>
</template>

<style lang="scss"
       scoped>
.gdpr-notice--wrapper {
    position:         fixed;
    bottom:           0;
    left:             0;
    z-index:          3147483603;
    display:          inline-flex;
    flex-flow:        column;
    align-items:      center;
    justify-content:  space-between;
    width:            stretch;
    padding:          1.5rem;
    background-color: var(--reshepe-color-secondary-700);
    box-shadow:       rgb(0 0 0 / 66%) 0 13px 16px, rgb(0 0 0 / 43%) 0 13px 16px;
    font-size:        1rem;
    text-align:       left;

    @include tablet {
        flex-flow: row;
    }

    .gdpr-notice--message {
        color: var(--reshepe-color-white-600);

        &__link {
            --link-internal-color:       var(--reshepe-color-primary-700);
            --link-internal-hover-color: var(--reshepe-color-primary-500);
        }
    }

    .gdpr-notice--buttons {
        display:     flex;
        align-items: center;
        margin-top:  2rem;

        @include tablet {
            margin-top: 0;
        }

        &__manage {
            --link-internal-color:             var(--reshepe-color-primary-700);
            --link-internal-arrow-color:       var(--reshepe-color-primary-700);
            --link-internal-hover-color:       var(--reshepe-color-primary-500);
            --link-internal-hover-arrow-color: var(--reshepe-color-primary-500);

            margin-right:                      1rem;
        }
    }
}
</style>
